$color-coil: #020814;
$color-middle-blue: #1890ff;

$color-icon: rgba(0,0,0,.25);
$color-icon-hover: #1890ff;

$bp992: 992;
$bp840: 840;
$bp768: 768;
$bp576: 576;


// Header height values
:root {
  --header-height: 64px;
}

@media (max-width: 768px) and (max-height: 1024px),
  (max-width: 1024px) and (max-height: 768px) {
  :root {
    --header-height: 48px;
  }
}
