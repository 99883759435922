@import '../../other/styles/mixins';
@import '../../other/styles/variables';

.Navigation {
  height: 100%;
  max-width: 100%;
  
  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--header-height);
    background-color: white;
    
    img {
      width: 160px;
      position: relative;
      top: 2px;
      
      @include mobile() {
        width: 180px;
      }
    }
  }
  
  &__menu {
    height: calc(100% - var(--header-height));
    padding-top: 12px !important;
  }
  
  .ant-menu-item-selected {
    background-color: transparent !important;
  }
  
  &__link {
    display: inline-block;
    width: 100%;
    padding: 0 1rem 0 1.5rem;
    color: rgba(white, 0.65) !important;
    
    &:hover {
      color: white !important;
    }
    
    &--active {
      background-color: $color-middle-blue;
      color: white !important;
    }
    
    .anticon {
      min-width: 20px;
    }
  }
}

/**/
.ant-menu.ant-menu-dark {
  background-color: #020814;
}
.ant-menu-dark.ant-menu-inline {
  border-right: 0;
}
.ant-menu.ant-menu-dark {
  background: #001529;
  color: hsla(0,0%,100%,.65);
}
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline {
  border-right: 1px solid #f0f0f0;
}
.ant-menu {
  font-feature-settings: "tnum";
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  box-sizing: border-box;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-variant: tabular-nums;
  //line-height: 1.5715;
  line-height: 0;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: left;
  transition: background .3s,width .3s cubic-bezier(.2,0,0,1) 0s;
}
/**/
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  width: 100%;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  border-right: 0;
  left: 0;
  margin-left: 0;
}
.ant-menu-inline.ant-menu-root .ant-menu-item {
  align-items: center;
  display: flex;
  transition: border-color .3s,background .3s,padding .1s cubic-bezier(.215,.61,.355,1);
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-dark .ant-menu-item {
  color: hsla(0,0%,100%,.65);
}
.ant-menu-inline .ant-menu-item {
  width: calc(100% + 1px);
}
.ant-menu-inline>.ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 40px;
  margin-bottom: 4px;
  margin-top: 4px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
}
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-item {
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  transition: border-color .3s,background .3s,padding .3s cubic-bezier(.645,.045,.355,1);
  white-space: nowrap;
}
/**/
.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content, {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item>* {
  flex: none;
}
.ant-menu-title-content {
  transition: color .3s;
}
.ant-menu-item .anticon+span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity .3s cubic-bezier(.645,.045,.355,1),margin .3s,color .3s;
}
