@import '../../other/styles/variables.scss';


.LoginPage {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  &__title {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  &__form {
    margin: 2rem !important;
    width: 300px;
    max-width: 300px;
    font-size: inherit !important;
  }

  &__icon {
    color: $color-icon;
  }

  &__submit {
    display: block;
    width: 100%;
  }

}
