@import 'mixins';
@import 'variables';
// todo remove
@import '../../pages/CompanyEditorPage/Editor.scss';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  background-color: transparent;
  color: #1890ff;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color .3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-pointer {
  cursor: pointer;
}

.app-striped {
  tbody,
  thead {
    background-color: white;
  }

  tbody tr:nth-child(odd) {
    background-color: whitesmoke;
  }
}

.app-img-responsive {
  max-height: 100% !important;
  max-width: 100% !important;
}
