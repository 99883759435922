.Editor {
  margin-top: 1rem;
  padding: 0 2rem 1rem 0;
  height: calc(100% - var(--header-height));
  overflow: auto;
  max-width: 700px;
  
  &--tall {
    //height: 100%;
  }
  
  &__title {
    margin: 1.25rem 1rem 0.5rem 0;
  }
  
  &__input {
    width: 100%;
  }
  
  &__checkbox {
    padding-left: 10px !important;
  }
  
  &__longLabel {
    .ant-form-item-label {
      flex-grow: 0.25 !important;
    }
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
  
  &__remove {
    margin-top: 32px;
  }
}
