@import 'mixins';
@import 'variables';


.TablePage {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}


.TableContainer {
  flex: 1;
  
  &__name {
    background-color: whitesmoke;
  }
  
  .ant-table {
    @include breakpoint($max: $bp576) {
      margin-top: 38px;
    }
  }
  
  .ant-pagination {
    position: absolute;
    top: calc(-36px - calc(var(--header-height) / 4 - 12px));
    right: 0;
    
    @include breakpoint($max: $bp576) {
      right: unset;
      left: 0;
    }
  }
  
  .ant-table-body {
    max-height: unset;
  }
}

.ant-table-thead > tr > th:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}
