@import '../../other/styles/mixins';
@import '../../other/styles/variables';

.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 24px;
  background-color: whitesmoke !important;
  height: var(--header-height);
  line-height: var(--header-height);
  
  &__icon {
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;
    
    &:hover {
      color: $color-icon-hover;
    }
  }
  
  &__search {
    flex: 1;
  
    @include mobile() {
      padding: 0 24px;
    }
  }
  
  &__trigger {
    display: none;
  
    @include mobile() {
      display: inline;
    }
  }
}
