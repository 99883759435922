@import '../../other/styles/variables.scss';

.Main {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  &__content {
    flex: 1;
    height: 100%;
    overflow: hidden;
    background: #f0f2f5;
  }
  
  &__main {
    height: calc(100% - var(--header-height));
  }
}
