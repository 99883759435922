.Search {
  width: 100%;
  max-width: 300px;

  &__dropdown {
    .ant-select-dropdown-menu-item-group-title {
      color: #666;
      font-weight: bold;
    }
    .ant-select-dropdown-menu-item-group {
      border-bottom: 1px solid #F6F6F6;
    }
    .ant-select-dropdown-menu-item {
      padding-left: 1rem;
    }
    .ant-select-dropdown-menu-item.show-all {
      text-align: center;
      cursor: default;
    }
    .ant-select-dropdown-menu {
      max-height: 300px;
    }
  }

}
