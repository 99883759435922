@import 'mixins';
@import 'variables';

.Tabs {
  
  &.ant-tabs {
    height: 100%;
  }
  
  .ant-tabs-nav {
    border-right: 1px solid lightgrey;
    max-width: 200px;
    min-width: 150px;
    padding-top: 30px;
    
    @include mobile {
      border-right: none;
      max-width: unset;
      padding-top: 0;
    }
  }
  
  .ant-tabs-nav-list {
    @include mobile {
      border-bottom: 1px solid lightgrey;
    }
  }
  
  .ant-tabs-tab {
    white-space: normal;
    text-align: left !important;
    
    @include mobile {
      white-space: unset;
      padding: 12px 8px;
    }
  }
  
  .ant-tabs-tab.ant-tabs-tab-active {
    background: ghostwhite;
  }
  
  .ant-tabs-tab + .ant-tabs-tab {
    @include mobile {
      margin-left: 8px;
    }
  }
  
  .ant-tabs-tabpane {
    overflow: hidden;
  }
  
  .ant-tabs-content {
    height: 100%;
  }
  
  .ant-tabs-content-holder {
    overflow-x: auto;
  }
}
