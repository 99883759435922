@import 'mixins';
@import 'variables';

.Editor2 {
  padding: 1rem 1.5rem 1rem 0;
  overflow: auto;
  max-width: 700px;
  height: 100%;
  
  @include mobile {
    margin: 0;
    padding: 0 16px 16px;
  }
  
  &__title {
    margin: 1.25rem 1rem 0.5rem 0;
    @include mobile {
      margin: 0.5rem 0rem 1rem 0;
    }
  }
  
  &__input {
    width: 100%;
  }
  
  &__checkbox {
    padding-left: 10px !important;
  }
  
  &__longLabel {
    .ant-form-item-label {
      flex-grow: 0.25 !important;
    }
  }
  
  .ant-form-item-label {
    @include mobile {
      padding: 0 0 0 12px;
    }
  }
  
  .ant-form-item {
    margin-bottom: 12px;
    @include mobile {
      margin-bottom: 20px;
    }
  }
  
  &__remove {
    margin-top: 32px;
  }
}


.EditorSimple {
  padding-left: 24px;
  
  @include mobile {
    padding-left: 0;
  }
}


.EditorContainer {
  height: calc(100% - var(--header-height)) !important;
  
  @include mobile {
    overflow: auto;
  }
  
  .Loading {
    background: rgba(0, 0, 0, 0.15);
  }
}

.EditorPage {
  display: flex;
  flex-direction: column;
  height: 100%;
}
