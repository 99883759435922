@import '~antd/dist/reset.css';
@import 'other/styles/common';
@import 'other/styles/editor';
@import 'other/styles/specific';
@import 'other/styles/table';
@import 'other/styles/tabs';
@import 'other/styles/variables';

#root {
  height: 100%;
  position: relative;
}
