@import '../../other/styles/mixins';
@import '../../other/styles/variables';

.Aside {
  height: 100%;
  width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #001529;
  
  @include mobile {
    display: none;
  }
  
  &__tablet {
    display: none;
    
    @include mobile {
      display: block;
    }
    
    .ant-drawer-body {
      padding: 0;
    }
  }
  
  &__nav {
    height: 100%;
  }
}
