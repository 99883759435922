@import 'variables';

.ant-menu.ant-menu-dark {
  background-color: $color-coil;
}

// Antd form item label:
.ant-form-item-label {
  background: ghostwhite;
  box-shadow: inset 2px 0 2px 2px whitesmoke;
  border-radius: 2px 0 0 2px;
  
  label {
    line-height: 1.23;
  }
}
.ant-form-item-no-colon {
  color: rgba(0,0,0,.65) !important;
}

.ant-table td {
  white-space: nowrap;
}
.ant-table-thead > tr > th {
  background-color: whitesmoke !important;
  font-size: 14px;
}
.ant-table-pagination.ant-pagination {
  margin: 0 1.25rem 0.5rem !important;
}
