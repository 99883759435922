@import 'variables';

@mixin breakpoint($min: 0, $max: 0) {
  $query: "all" !default;
  
  @if $min != 0 and $max != 0 {
    $query: "(min-width: #{$min}px) and (max-width: #{$max}px)";
  }
  @else if $min != 0 and $max == 0 {
    $query: "(min-width: #{$min}px)";
  }
  @else if $min == 0 and $max != 0 {
    $query: "(max-width: #{$max}px)";
  }
  @media #{$query} {
    @content;
  }
}

@mixin mobile() {
  @media (max-height: #{$bp992}px) and (max-width: #{$bp576}px), (max-height: #{$bp576}px) and (max-width: #{$bp992}px) {
    @content;
  }
}
